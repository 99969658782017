import React from 'react';

type FunnelContextType = {
  externalFunnelLoaded: boolean;
  setExternalFunnelLoaded: (externalFunnelLoaded: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunction = (): void => {};

export const FunnelContext = React.createContext<FunnelContextType>({
  externalFunnelLoaded: false,
  setExternalFunnelLoaded: emptyFunction,
});
