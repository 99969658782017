import { QUNOMEDICAL_BRAND_ID } from 'constants/brand';
import { PRODUCTION } from 'constants/environment';
import {
  UC_SCRIPT_SRC,
  UC_SCRIPT_ID,
  UC_SETTINGS_ID,
} from 'constants/usercentrics';
import { CookieContext } from 'context/cookie';
import * as Sentry from '@sentry/react';
import CookieNotification from 'components/Common/CookieNotification';
import FullStory from 'components/Common/FullStory';
import GoogleAnalytics from 'components/Common/GoogleAnalytics';
import config from 'config';
import { AppInitContext } from 'context/appInit';
import { CurrencyContext } from 'context/currency';
import { FunnelContext } from 'context/funnel';
import { LocaleContext } from 'context/locale';
import { useAnalytics } from 'hooks/useAnalytics';
import 'intersection-observer';
import Cookie from 'js-cookie';
import localesContent from 'locales';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import 'scripts/polyfills';
import {
  storeUserCountry,
  storeUserJourney,
} from 'services/tracking/userJourney';
import '../styles/main.scss';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import '../styles/contentful.scss';

type PageProps = object;

type Props = {
  Component: (props: PageProps) => JSX.Element;
  pageProps: PageProps;
};

const App = ({ Component, pageProps }: Props): JSX.Element => {
  const router = useRouter();
  // eslint-disable-next-line
  // @ts-ignore
  const { brand } = pageProps;

  /**
   * HACK:
   *
   * Since at Vercel, on fallback routes, the dynamic routes
   * default to the dynamic variable name (in this case, `[locale]`)
   * when they are not computed, we need to fallback here both for
   * when it is not defined and when it is '[locale]'.
   */
  let routerLocale = router.query.locale;
  if (routerLocale === '[locale]' || !routerLocale) {
    routerLocale = config.defaultLocale;
  }

  const [locale, setLocale] = useState<string>(routerLocale as string);
  const [currency, setCurrency] = useState<string>('');
  const [cookies, setCookies] = useState<CookieContext>(null);

  const [hasAppInit, setHasAppInit] = useState(false);

  const [externalFunnelLoaded, setExternalFunnelLoaded] = useState(false);
  const [hasCookieScriptLoaded, setHasCookieScriptLoaded] = useState(false);

  const { trackPageViewed } = useAnalytics();

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      trackPageViewed();
    });

    setCurrency(Cookie.get('qm_currency') || config.defaults.currency);
    storeUserJourney(router.asPath, document.location.href, document.referrer);
    storeUserCountry();
  }, []);

  useEffect(() => {
    if (!brand || brand?.sys.id !== QUNOMEDICAL_BRAND_ID || hasAppInit) {
      return;
    }

    const hasStoredCookieSettings =
      window.localStorage && window.localStorage.uc_user_interaction;

    if (hasStoredCookieSettings) {
      // Create script
      const script = document.createElement('script');
      script.src = UC_SCRIPT_SRC;
      script.id = UC_SCRIPT_ID;
      script.dataset.settingsId = UC_SETTINGS_ID;
      script.async = true;

      if (!PRODUCTION) {
        script.dataset.disableTracking = 'true';
        script.dataset.version = 'preview';
      }

      document.head.appendChild(script);
      setHasCookieScriptLoaded(true);
    }

    window.addEventListener('mousemove', handleAppInit);
    window.addEventListener('touchstart', handleAppInit);
    window.addEventListener('scroll', handleAppInit, { passive: true });

    const timer = setTimeout(
      () => {
        if (!hasAppInit) {
          handleAppInit();

          // clear the old timer
          if (timer) {
            clearTimeout(timer);
          }
        }
      },
      hasStoredCookieSettings ? 0 : 8000,
    );

    return () => {
      window.removeEventListener('mousemove', handleAppInit);
      window.removeEventListener('touchstart', handleAppInit);
      window.removeEventListener('scroll', handleAppInit);
      clearTimeout(timer);
    };
  }, [brand]);

  const handleAppInit = (): void => {
    setHasAppInit(true);

    window.removeEventListener('mousemove', handleAppInit);
    window.removeEventListener('touchstart', handleAppInit);
    window.removeEventListener('scroll', handleAppInit);
  };

  return (
    <Sentry.ErrorBoundary>
      <LocaleContext.Provider value={{ locale, setLocale, localesContent }}>
        <CurrencyContext.Provider value={{ currency, setCurrency }}>
          <AppInitContext.Provider value={{ hasAppInit, setHasAppInit }}>
            <CookieContext.Provider
              value={{
                setCookies,
                cookies,
              }}
            >
              <FunnelContext.Provider
                value={{
                  externalFunnelLoaded,
                  setExternalFunnelLoaded,
                }}
              >
                <FullStory />
                <ContentfulLivePreviewProvider
                  locale="en-US"
                  enableInspectorMode
                  enableLiveUpdates
                  debugMode={false}
                >
                  <Component {...pageProps} />
                </ContentfulLivePreviewProvider>
                <CookieNotification skipScriptLoad={hasCookieScriptLoaded} />
                <GoogleAnalytics />
              </FunnelContext.Provider>
            </CookieContext.Provider>
          </AppInitContext.Provider>
        </CurrencyContext.Provider>
      </LocaleContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
