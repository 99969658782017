import React from 'react';

type AppInitContextType = {
  setHasAppInit: (hasAppInit: boolean) => void;
  hasAppInit: boolean;
};

export const AppInitContext = React.createContext<AppInitContextType>({
  setHasAppInit: () => '',
  hasAppInit: false,
});
