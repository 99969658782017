import { getCustomContentTypes } from './routes.config';

export default {
  app: {
    domains: {
      production: 'quno-next.now.sh',
      staging: 'qunomedical.vercel.app',
      local: 'localhost:3030',
      base: {
        production: 'qunomedical.com',
        local: 'localhost',
      },
      proxy: {
        production: 'www.qunomedical.com',
        staging: 'www-staging.qunomedical.com',
      },
    },
  },
  retry: {
    retries: 5,
    retry: 500,
  },
  routeLocales: ['en', 'de', 'nl', 'fr', 'es', 'it'],
  locales: [
    {
      main: 'en',
      contentful: 'en-US',
      fullName: 'locales.en',
    },
    {
      main: 'de',
      contentful: 'de-DE',
      fullName: 'locales.de',
    },
    {
      main: 'nl',
      contentful: 'nl-NL',
      fullName: 'locales.nl',
    },
    {
      main: 'fr',
      contentful: 'fr-FR',
      fullName: 'locales.fr',
    },
    {
      main: 'es',
      contentful: 'es-ES',
      fullName: 'locales.es',
    },
    {
      main: 'it',
      contentful: 'it-IT',
      fullName: 'locales.it',
    },
  ],
  defaultLocale: 'en',
  currencies: {
    EUR: { name: 'EUR', symbol: '€' },
    GBP: { name: 'GBP', symbol: '£' },
    USD: { name: 'USD', symbol: '$' },
  },
  defaults: {
    imageFallbackSize: 500,
    secondsForPageRevalidation: 600,
    serviceFee: 250,
    qdLoadingScreenTimeSeconds: 5,
    bookingApi: 'https://booking.api.qunomedical.com',
    qunoApi: 'https://api.qunomedical.com',
    facebookTrackingId: '1707862066157334',
    googleAnalytics: 'UA-64043738-4',
    googleVerification: 'UF6qe2rlnzPVWD9aExgWqsS_8sYlA6r_Tsu2OGnrEYE',
    country: 'GB',
    currency: 'EUR',
    maxQunoscorePoints: 35,
    newPostsCount: 3,
    postsPerPage: 6,
    tagsCount: 15,
    treatmentsCardSlider: {
      threshold: 2,
      responsive: {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 2,
          partialVisibilityGutter: 40,
        },
        desktop: {
          breakpoint: { max: 2999, min: 1024 },
          items: 2,
          partialVisibilityGutter: 40,
        },
        tablet: {
          breakpoint: { max: 1023, min: 768 },
          items: 2,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: { max: 767, min: 0 },
          items: 1,
          partialVisibilityGutter: 60,
        },
      },
    },
    treatmentsCardItemCount: 4,
    // eslint-disable-next-line security/detect-unsafe-regex
    emailRegex: /^\w+([.-]?\w+)*@\w+([.-]?\w)*\.([a-z]{2,})+$/i,
    email: 'contact@qunomedical.com',
    whatsApp: '447723488550',
    messengerUrl: 'https://m.me/qunomedical',
    algolia: {
      hitsPerPage: 9,
      paginationPadding: 1,
      refinementListLimit: 25,
    },
    zapierCatchUrl: 'https://hooks.zapier.com/hooks/catch',
    beforeAndAfterPhotosPreload: 10,
    beforeAndAfterPhotosGridContainerLimit: 500,
  },
  pages: {
    contentTypes: [
      'generalPage',
      'specialityPage',
      'doctorProfile',
      'clinicPage',
      'authorList',
      'clinic',
    ],
    customContentTypes: getCustomContentTypes(),
    generalPage: {
      header: 'bg-secondary',
    },
    treatmentPage: {
      header: 'bg-white',
    },
    doctorProfile: {
      header: 'bg-white',
    },
    blogPage: {
      en: 'blog',
      de: 'blog',
    },
  },
  subpages: {
    contentTypes: ['treatmentPage', 'author'],
    parentTypes: {
      author: 'authorList',
    },
  },
  level_3: {
    contentTypes: ['locationPage'],
  },
  level_4: {
    contentTypes: ['locationPage'],
  },
  dynamicLengthPages: {
    contentTypes: ['landingPage', 'referFriendRewardLandingPage'],
  },
  cache: {
    root: '.cache',
    routes: 'routes.json',
    tailwind: 'tailwind.json',
    footers: 'footers.json',
  },
  contactForm: {
    preferredCountries: ['de', 'gb', 'ie', 'us'],
    options: {
      info: [
        {
          value: 'myself',
          label: 'contact.form.myself',
        },
        {
          value: 'other',
          label: 'contact.form.other',
        },
      ],
      timeframe: [
        {
          value: 'asap',
          label: 'contact.form.asap',
        },
        {
          value: 'nextWeeks',
          label: 'contact.form.nextWeeks',
        },
        {
          value: 'fewMonths',
          label: 'contact.form.fewMonths',
        },
        {
          value: 'notDecided',
          label: 'contact.form.notDecided',
        },
      ],
    },
  },
  socials: [
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/qunomedical',
    },
    {
      name: 'Twitter',
      link: 'https://www.twitter.com/qunomedical',
    },
    {
      name: 'Instagram',
      link: 'https://www.instagram.com/qunomedical/',
    },
    {
      name: 'Youtube',
      link: 'https://www.youtube.com/channel/UCkG3rDQd8ivu4GcMgYUAA9g',
    },
    {
      name: 'LinkedIn',
      link: 'https://www.linkedin.com/company/qunomedical',
    },
  ],
  homepage: {
    dfuBanner: ['de', 'en'],
    headerReviewsLink: ['de', 'en'],
    popularTreatmentPrice: ['de', 'en'],
    reviewerLocation: ['de', 'en'],
    search: ['de', 'en'],
    specialitiesOverview: ['de', 'en'],
    qunosuite: ['de', 'en'],
  },
  search: {
    recent: {
      en: [
        {
          title: 'Hair Transplant',
          sub_title: 'Hair Transplant',
          url: '/en/plastic-surgery/hair-transplant',
        },
        {
          title: 'Breast Augmentation',
          sub_title: 'Breast Augmentation',
          url: '/en/plastic-surgery/breast-augmentation',
        },
        {
          title: 'Dental Implants',
          sub_title: 'Dental Implants',
          url: '/en/dentistry/dental-implant',
        },
        {
          title: 'Liposuction',
          sub_title: 'Liposuction',
          url: '/en/plastic-surgery/liposuction',
        },
        {
          title: 'Gastric Sleeve Surgery',
          sub_title: 'Gastric Sleeve Surgery',
          url: '/en/weight-loss-surgery/gastric-sleeve',
        },
        {
          title: 'Prostate Cancer Treatment',
          sub_title: 'Prostate Cancer Treatment',
          url: '/en/oncology/diagnosis-and-treatment-of-prostate-cancer',
        },
        {
          title: 'Hip Replacement',
          sub_title: 'Hip Replacement',
          url: '/en/orthopedics/hip-replacement',
        },
        {
          title: 'Bone Marrow Transplant',
          sub_title: 'Bone Marrow Transplant',
          url: '/en/oncology/bone-marrow-transplant',
        },
      ],
      de: [
        {
          title: 'Haartransplantation',
          sub_title: 'Haartransplantation',
          url: '/de/plastische-chirurgie/haartransplantation',
        },
        {
          title: 'Nasenkorrektur',
          sub_title: 'Nasenkorrektur',
          url: '/de/plastische-chirurgie/nasenkorrektur',
        },
        {
          title: 'Zahnimplantat',
          sub_title: 'Zahnimplantate',
          url: '/de/zahnmedizin/zahnimplantat',
        },
        {
          title: 'Fettabsaugung',
          sub_title: 'Fettabsaugung',
          url: '/de/plastische-chirurgie/fettabsaugung',
        },
        {
          title: 'Schlauchmagen',
          sub_title: 'Schlauchmagen',
          url: '/de/operative-gewichtsreduktion/schlauchmagen',
        },
        {
          title: 'Behandlung von Prostatakrebs',
          sub_title: 'Behandlung von Prostatakrebs',
          url: '/de/onkologie/diagnose-und-behandlung-von-prostatakrebs',
        },
        {
          title: 'Hüftprothese',
          sub_title: 'Hüftprothese',
          url: '/de/orthopaedie/hueftprothese',
        },
        {
          title: 'Knochenmarktransplantation',
          sub_title: 'Knochenmarktransplantation',
          url: '/de/onkologie/knochenmarktransplantation',
        },
      ],
    },
  },
  countries: {
    GB: {
      phone: '+44 1466 455033',
      currency: 'GBP',
      alternate: 'UK',
    },
    DE: {
      phone: '+49 30 200042054',
      currency: 'EUR',
      whatsApp: '4915735988550',
    },
    AT: {
      phone: '+43 720 229111',
      currency: 'EUR',
    },
    IE: {
      phone: '+353 1 592 6118',
      currency: 'EUR',
    },
    US: {
      phone: '+1 714 710 8171',
      currency: 'USD',
    },
    CA: {
      phone: '+1 647 557 2838',
      currency: 'CAD',
    },
    AU: {
      phone: '+61 488 857 557',
      currency: 'AUD',
    },
    CH: {
      phone: '+41 61 539 16 53',
      currency: 'CHF',
    },
    DK: {
      phone: '+45 92 45 32 90',
      currency: 'DKR',
    },
    SE: {
      phone: '+46 10 195 64 26',
      currency: 'SEK',
    },
    FI: {
      phone: '+358 75 3267944',
      currency: 'EUR',
    },
    NL: {
      phone: '+31 20 399 7231',
      currency: 'EUR',
    },
    BE: {
      phone: '+32 460 20 44 52',
      currency: 'EUR',
    },
    ES: {
      phone: '+34 910 78 09 87',
      currency: 'EUR',
    },
    IT: {
      phone: '+39 02 3056 4576',
      currency: 'EUR',
    },
    FR: {
      phone: '+33 4 81 69 48 16',
      currency: 'EUR',
    },
  },
};
