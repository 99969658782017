// Polyfill for 'closest' - based on: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if ('undefined' !== typeof window) {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s: string) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let el = this;

      do {
        if (Element.prototype.matches.call(el, s)) {
          return el;
        }

        el = (el.parentElement || el.parentNode) as Element;
      } while (el !== null && el.nodeType === 1);

      return null;
    };
  }
}

export {};
