export enum Stage {
  LOCAL = 'local',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const LOCAL = process.env.STAGE === Stage.LOCAL;

export const STAGING = process.env.STAGE === Stage.STAGING;

export const PRODUCTION = process.env.STAGE === Stage.PRODUCTION;

export const STATIC_PATH_FALLBACK = process.env.STATIC_OUTPUT === '1';
