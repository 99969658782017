import React from 'react';
import config from 'config';
import localesContent from 'locales';

type LocaleContextType = {
  locale: string;
  setLocale: (locale: string) => void;
  localesContent: typeof localesContent;
};

export const LocaleContext = React.createContext<LocaleContextType>({
  locale: config.defaultLocale,
  setLocale: () => null,
  localesContent,
});
