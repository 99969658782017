import { Service } from 'constants/usercentrics';
import { GOOGLE_ANALYTICS_ID } from 'constants/brand';
import { useContext, useEffect } from 'react';
import { AppInitContext } from 'context/appInit';

const GoogleAnalytics = (): JSX.Element => {
  const { hasAppInit } = useContext(AppInitContext);

  useEffect(() => {
    if (hasAppInit) {
      const mainScript = document.createElement('script');
      const script = document.createElement('script');
      mainScript.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}"`;
      mainScript.type = 'text/plain';
      mainScript.dataset.usercentrics = Service.GOOGLE_ANALYTICS;

      script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', '${GOOGLE_ANALYTICS_ID}');

      gtag("consent", "default", {
        ad_user_data: "denied",
        ad_personalization: "denied",
        ad_storage: "denied",
        analytics_storage: "denied",
        wait_for_update: 2000 // milliseconds to wait for update
      });

      // Enable ads data redaction by default [optional]
      gtag("set", "ads_data_redaction", true);

      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${GOOGLE_ANALYTICS_ID}'); //replace GTM-XXXXXX with Google Tag Manager ID
      `;

      document.head.appendChild(mainScript);
      document.head.appendChild(script);

      return () => {
        if (script && script.parentNode?.nodeName === 'HEAD') {
          document.head.removeChild(mainScript);
          document.head.removeChild(script);
        }
      };
    }
  }, [hasAppInit]);

  return <></>;
};

export default GoogleAnalytics;
