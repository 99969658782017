import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import nl from './nl.json';
import type { ConfigLocale } from 'types/PageData';

export type Locales = Record<string, ConfigLocale>;

const locales = { de, en, es, fr, it, nl } as unknown as Locales;

export default locales;
