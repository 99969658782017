import React from 'react';
import config from 'config';

type CurrencyContextType = {
  setCurrency: (currency: string) => void;
  currency: string;
};

export const CurrencyContext = React.createContext<CurrencyContextType>({
  setCurrency: () => '',
  currency: config.defaults.currency,
});
