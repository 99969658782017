/* eslint-disable no-prototype-builtins */
import {
  Service,
  UC_SCRIPT_SRC,
  UC_SCRIPT_ID,
  UC_SETTINGS_ID,
} from 'constants/usercentrics';
import { PRODUCTION, Stage } from 'constants/environment';
import * as Sentry from '@sentry/react';
import { useContext, useEffect } from 'react';
import { AppInitContext } from 'context/appInit';
import { CookieContext } from 'context/cookie';
import { useAnalytics } from 'hooks/useAnalytics';

export type CookiesList = Record<string, boolean>;

export const isServiceAllowed = (
  cookies: CookiesList | null,
  serviceName: string,
): { serviceName: string; serviceStatus: boolean } => {
  const serviceStatus = !!cookies?.[serviceName];

  return { serviceName, serviceStatus };
};

const CookieNotification = ({
  skipScriptLoad,
}: {
  skipScriptLoad?: boolean;
}): JSX.Element => {
  const { hasAppInit } = useContext(AppInitContext);
  const { cookies, setCookies } = useContext(CookieContext);

  const { trackPageViewed } = useAnalytics();

  useEffect(() => {
    if (hasAppInit) {
      const script = document.createElement('script');

      // Create script
      if (!skipScriptLoad) {
        script.src = UC_SCRIPT_SRC;
        script.id = UC_SCRIPT_ID;
        script.dataset.settingsId = UC_SETTINGS_ID;
        script.async = true;

        if (!PRODUCTION) {
          script.dataset.disableTracking = 'true';
          script.dataset.version = 'preview';
        }

        document.head.appendChild(script);
      }

      addEventListener('consentUpdate', handlePrivacy);

      return () => {
        if (script && script.parentNode?.nodeName === 'HEAD') {
          document.head.removeChild(script);
        }

        removeEventListener('consentUpdate', handlePrivacy);
      };
    }
  }, [hasAppInit]);

  useEffect(() => {
    if (isServiceAllowed(cookies, Service.GOOGLE_ANALYTICS).serviceStatus) {
      trackPageViewed();
    }

    if (isServiceAllowed(cookies, Service.SENTRY).serviceStatus) {
      Sentry.init({
        enabled: PRODUCTION,
        dsn: process.env.SENTRY_DSN,
        environment:
          process.env.NEXT_PUBLIC_VERCEL_ENV ||
          process.env.STAGE ||
          Stage.LOCAL,
        autoSessionTracking: false,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 0.5,
        beforeSend(event) {
          if (event.user) {
            delete event.user.email;
            delete event.user.ip_address;
            delete event.user.username;
          }

          return event;
        },
      });
    }
  }, [cookies]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePrivacy = (e: any): void => {
    const { detail } = e;
    setCookies(detail);
    if (e.detail && e.detail.event == 'consent_status') {
      // Please replace the analytics service name here with the customized service
      // eslint-disable-next-line no-var
      var ucAnalyticsService = 'Google Analytics';
      // Please replace the ad service name here with the customized service
      // eslint-disable-next-line no-var
      var ucAdService = 'Google Ads Remarketing';

      if (
        e.detail.hasOwnProperty(ucAnalyticsService) &&
        e.detail.hasOwnProperty(ucAdService)
      ) {
        window.gtag('consent', 'update', {
          ad_storage: e.detail[ucAdService] ? 'granted' : 'denied',
          ad_user_data: e.detail[ucAdService] ? 'granted' : 'denied',
          ad_personalization: e.detail[ucAdService] ? 'granted' : 'denied',
          analytics_storage: e.detail[ucAnalyticsService]
            ? 'granted'
            : 'denied',
        });
      } else {
        if (e.detail.hasOwnProperty(ucAdService)) {
          window.gtag('consent', 'update', {
            ad_storage: e.detail[ucAdService] ? 'granted' : 'denied',
            ad_user_data: e.detail[ucAdService] ? 'granted' : 'denied',
            ad_personalization: e.detail[ucAdService] ? 'granted' : 'denied',
          });
        }

        if (e.detail.hasOwnProperty(ucAnalyticsService)) {
          gtag('consent', 'update', {
            analytics_storage: e.detail[ucAnalyticsService]
              ? 'granted'
              : 'denied',
          });
        }
      }
    }
  };

  return <></>;
};

export default CookieNotification;
