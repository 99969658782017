import { PRODUCTION } from 'constants/environment';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any;
  }
}

const isTrackingEnabled = (): boolean => {
  if (typeof window === 'undefined' || !('gtag' in window) || !PRODUCTION) {
    return false;
  }

  return true;
};

export const useAnalytics = (): {
  trackPageViewed: (path?: string) => void;
  trackEvent: (params: Record<string, string>, callback?: () => void) => void;
} => {
  return {
    trackPageViewed(path) {
      if (!isTrackingEnabled()) {
        return;
      }

      if (path) {
        window.gtag('event', 'page_view', { page_location: path });
        return;
      }

      const { pathname, search } = window.location || {};
      window.gtag('event', 'page_view', {
        page_location: `${pathname}${search}`,
      });
    },
    trackEvent(params, callback) {
      if (!isTrackingEnabled()) {
        return;
      }

      window.gtag('event', params.action, {
        event_category: params.category,
        event_label: params.label,
        value: params.value,
      });

      callback?.();
    },
  };
};

/**
 * Set the value of a custom dimension of name `name` to the value `value`
 *
 * Not exported as a hook to be used within util functions as well
 */
export const setCustomDimension = (
  dimensionName: string,
  value: unknown,
): void => {
  if (!isTrackingEnabled()) {
    return;
  }

  window.gtag('set', { [dimensionName]: value });
};
